import React, { useContext, useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';

import Layout from "../components/layout"
import Seo from "../components/seo"

//sections
import ProcessSection1 from './sections/process/processSection1';
import ProcessSection2 from './sections/process/processSection2';
import BlogSection from './sections/BlogSection';

const Process = ({data}) => {
  const mainData = data.allWpPage.nodes[0].processACF;
  console.log(mainData);
//   useEffect(() => {
//     let unmounted = false;
//     gsap.registerPlugin(ScrollTrigger)

//     setTimeout(() => {
//       if (!unmounted) {
//       }
//     }, 1000);
     
//     return () => {
//       unmounted = true;
//     }

//   }, []);
  return (
    <Layout>
      <Seo title="Process" />
      <div className="page-wrapper process-page" id="process-page-wrapper">
        <ProcessSection1 
            mainTitle={parse(mainData.prcocessSection1Title)}
            mainDescription={parse(mainData.prcocessSection1Description)}
            image1x={
              {
                sourceUrl: mainData.prcocessSection1Image1x.sourceUrl,
                altText: mainData.prcocessSection1Image1x.altText,
                width: mainData.prcocessSection1Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.prcocessSection1Image2x.sourceUrl,
                altText: mainData.prcocessSection1Image2x.altText,
                width: mainData.prcocessSection1Image2x.width,
              }
            }
        />
        <ProcessSection2 
          sidebarlist={mainData.designProcessSidebar}
          contentlist={mainData.designProcessContent}
        />
        <BlogSection />
      </div>
    </Layout>
  )
}

export default Process;

export const processQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "process"}}) {
        nodes {
          processACF {
            prcocessSection1Title
            prcocessSection1Description
            prcocessSection1Image1x {
              altText
              sourceUrl
              width
            }
            prcocessSection1Image2x {
              altText
              sourceUrl
              width
            }

            designProcessSidebar {
              processName
              extraClasses
              id
            }
            designProcessContent {
              contentTitle
              contentDescription
              contentImageList {
                image1x {
                  altText
                  sourceUrl
                  width
                }
                image2x {
                  altText
                  sourceUrl
                  width
                }
              }
              contentTitleAndDescriptionList {
                title
                description
                image1x {
                  altText
                  sourceUrl
                  width
                }
                image2x {
                  altText
                  sourceUrl
                  width
                }
              }
              id
            }
          }
        }
    }
}`

